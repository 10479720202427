import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
// import Reaptcha from 'reaptcha';
import { Form } from 'react-final-form';

import Document from '../components/Document';
import CourseOutOfDateSection from '../components/pageSections/CourseOutOfDateSection';
import Section from '../components/Section';
import Column from '../components/Column';
import Markdown from '../components/Markdown';
import CourseAttendeeForm from '../components/CourseAttendeeForm';
import BuyerForm from '../components/BuyerForm';
import OrderSummary from '../components/OrderSummary';
import StyledSelect from '../components/StyledSelect';

import { dateIsTodayOrInTheFuture, firstDay } from '../lib/utils';
import { format } from 'date-fns';

import useAttendeesState from '../lib/hooks/useAttendeesState/index';

import {
  brownGrey,
  bluishPurple,
  veryLightBluishGray,
  breakpoints,
  darkishPink,
} from '../css-utilities';
import { FormInput } from '../components/Input';

const BookingPage = ({ data: { course, occasion } }) => {
  const orderForm = useRef();
  // const orderCaptcha = useRef();

  // const captchaValidation = useCallback(() => {
  //   orderCaptcha.current.execute();
  // });

  const submitForm = useCallback(() => {
    // submit form
    orderForm.current.submit();
  });

  const {
    handleSetNumberOfAttendees,
    numberOfAttendees,
    attendees,
  } = useAttendeesState(occasion.availableSeats);

  const firstDate = firstDay(occasion.allDates);

  if (!dateIsTodayOrInTheFuture(firstDate) || occasion.availableSeats === 0) {
    const filteredCourseOccasions = course.courseOccasions.filter(occ => {
      const fd = firstDay(occ.allDates);
      return dateIsTodayOrInTheFuture(fd) && occ.availableSeats != 0;
    });

    const text =
      occasion.availableSeats === 0
        ? 'Det finns inga lediga platser'
        : 'Kurstillfället har redan varit';

    return (
      <Document seo={course.seo}>
        <CourseOutOfDateSection
          courseName={course.name}
          occasions={filteredCourseOccasions}
          text={text}
        />
      </Document>
    );
  }

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Document seo={course.seo}>
      <div className="booking__wrapper">
        <Form onSubmit={submitForm}>
          {({ handleSubmit, values }) => {
            const renderAttendeeFields = attendees.map(
              (attendee, index) =>
                attendee.active && (
                  <Column key={attendee.id} width="100">
                    <CourseAttendeeForm
                      attendeeNumber={index + 1}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      values={values}
                    />
                  </Column>
                )
            );

            return (
              <form
                action={`https://formcarry.com/s/${
                  process.env.GATSBY_FORMCARRY_BOOKING_ID
                }`}
                method="POST"
                onSubmit={handleSubmit}
                ref={orderForm}
              >
                <div className="flex wrap attendees-form">
                  <section className="attendees-form-section-wrapper">
                    <Section noMargin noPadding>
                      <Column width="100">
                        <h4 className="course-name">{course.name}</h4>
                        <Markdown source={occasion.text} />
                      </Column>

                      <Column width="100">
                        <input
                          name="course"
                          type="hidden"
                          value={course.name}
                        />
                        <input
                          name="date"
                          type="hidden"
                          value={format(firstDate, 'YYYY-MM-D')}
                        />
                        <BuyerForm />
                      </Column>
                    </Section>

                    <Section noMargin noPadding>
                      <Column width="100">
                        <h3>Antal deltagare</h3>
                      </Column>
                      <div className="select">
                        <StyledSelect
                          currentValue={numberOfAttendees}
                          length={occasion.availableSeats}
                          onChange={handleSetNumberOfAttendees}
                        />

                        {occasion.availableSeats < 5 ? (
                          <span className="notice">
                            {`Endast ${occasion.availableSeats} plats${
                              occasion.availableSeats === 1 ? '' : 'er'
                            } kvar vid detta tillfälle!`}
                          </span>
                        ) : null}
                      </div>

                      {renderAttendeeFields}
                    </Section>

                    <Section noMargin noPadding>
                      <h3>Övrigt</h3>
                      <FormInput label="Meddelande..." name="other" textarea />
                    </Section>
                  </section>

                  <aside>
                    {/* <Reaptcha
                      onVerify={submitForm}
                      ref={orderCaptcha}
                      sitekey="6LeHE6QUAAAAAK93oZugGnxdvtEHHDtGgwK4E7k3"
                      size="invisible"
                    /> */}
                    <OrderSummary
                      attendees={attendees}
                      course={course}
                      coursePrice={course.price}
                      numberOfAttendees={numberOfAttendees}
                      occasion={occasion}
                    />
                  </aside>
                </div>
              </form>
            );
          }}
        </Form>
      </div>

      <style jsx>
        {`
          .booking__wrapper {
            grid-column: 1 / -1;
          }
          .course-name {
            color: ${bluishPurple};
          }
          .attendees-form {
            margin-left: none;
            position: relative;
          }

          .attendees-form-section-wrapper {
            width: 100%;
            margin: 50px 0;
            padding: 0 20px;
          }

          aside {
            position: relative;
            width: 100%;
            background: ${veryLightBluishGray};
          }

          .select {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }

          .notice {
            width: 265px;
            height: 18px;
            margin-left: 35px;
            font-family: Lato;
            font-size: 15px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${darkishPink};
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .attendees-form-section-wrapper {
              width: 50%;
              margin: 100px 0;
            }
          }

          @media (min-width: ${breakpoints.tablet}) {
            .attendees-form-section-wrapper {
              width: 60%;
            }

            aside {
              width: 40%;
            }
          }

          @media (min-width: ${breakpoints.desktopMD}) {
            .attendees-form-section-wrapper {
              width: 70%;
              padding-left: 100px;
              padding-right: 100px;
            }
            aside {
              width: 30%;
              margin-top: 0;
            }
          }

          textarea {
            width: 100%;
            height: 250px;
            max-width: 100%;
            min-width: 100%;
            font-family: 'Lato';
            font-size: 14px;
            color: ${brownGrey};
            padding: 14px 30px;
            border-radius: 20px;
            border: none;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          }
        `}
      </style>
    </Document>
  );
};

BookingPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query bookingPageQuery($courseId: String!, $occasionId: String!) {
    course: datoCmsCourse(id: { eq: $courseId }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      courseOccasions {
        id
        text
        location
        discount
        seats
        availableSeats
        allDates {
          datetime
        }
        courseLeaders {
          id
          firstname
          lastname
        }
      }
      name
      tagline
      bannerImage {
        url
      }
      description
      courseImage {
        url
      }
      price
      length
      location
      basePriorKnowledge {
        name
        id
      }
      meritoriousPriorKnowledge {
        name
        id
      }
      priorKnowledgeImage {
        url
      }
      courseInformationHeader
      courseContent
      schedule
      coursePlanIntro
      coursePlan {
        text
        id
      }
      courseLeaders {
        firstname
        lastname
        bio
        image {
          url
        }
      }
      courseCategories {
        name
      }
      actionCard {
        title
        text
        image {
          url
        }
        btnTitle
        path
      }
    }

    occasion: datoCmsCourseOccasion(id: { eq: $occasionId }) {
      id
      text
      location
      discount
      seats
      availableSeats
      allDates {
        datetime
        endDate
      }
      courseLeaders {
        id
        firstname
        lastname
      }
    }
  }
`;

BookingPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BookingPage;
