import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../Section';
import Column from '../../Column';
import CourseOccasion from '../../CourseOccasions/Occasion';
import LinkButton from '../../LinkButton';
import { bluishPurple } from '../../../css-utilities';

const CourseOutOfDateSection = ({ courseName, occasions, text }) => {
  const renderOccasions = occasions.map(occasion => (
    <div className="flex" key={occasion.id}>
      <CourseOccasion course={courseName} occasion={occasion} />
    </div>
  ));

  return (
    <Section>
      <Column width="100">
        <span className="course">{courseName}</span>
        <h1>{text}</h1>
        {occasions.length ? (
          <>
            <p>Välj ett annat datum</p>
            {renderOccasions}
          </>
        ) : (
          <>
            <p>{`Just nu finns inga kurstillfällen för ${courseName}.`}</p>
            <LinkButton
              arrowDirection="right"
              dark
              label="Gå till våra andra kurser"
              to="/utbildningar"
            />
          </>
        )}
      </Column>

      <style jsx>
        {`
          .course {
            font-size: 20px;
            color: ${bluishPurple};
            font-weight: bold;
            font-family: 'Poppins';
          }
        `}
      </style>
    </Section>
  );
};

CourseOutOfDateSection.propTypes = {
  courseName: PropTypes.string.isRequired,
  occasions: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
};

export default CourseOutOfDateSection;
