import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';

import Section from '../Section';
import Button from '../Button';
import { FormCheckbox } from '../CheckBox';
import Link from '../Link';
// import CaptchaTos from '../Form/CaptchaTos';

import { formatCurrencySEK } from '../../lib/utils';
import { ErrorTypes } from '../../lib/validation';

import {
  bluishPurple,
  greenishcyan,
  greyishBrown,
  veryLightPink,
  breakpoints,
  darkishPink,
} from '../../css-utilities';

const OrderSummary = ({
  course,
  occasion,
  numberOfAttendees,
  orderSummaryClassName,
  coursePriceWithDiscount,
  fullCoursePrice,
}) => {
  const renderCourseLeaders = occasion.courseLeaders.map(leader => (
    <li className="list-items" key={leader.id}>
      {`${leader.firstname} ${leader.lastname}`}
    </li>
  ));

  const dates = occasion.allDates.map(date => (
    <li className="list-items" key={JSON.stringify(date.datetime)}>
      {format(date.datetime, 'DD MMMM YYYY • HH:mm', { locale: sv }) +
        format(date.endDate, ' - HH:mm', { locale: sv })}
    </li>
  ));

  return (
    <div className={`order-summary-form ${orderSummaryClassName}`}>
      <Section>
        <div className="scroll__wrapper">
          <h3>Sammanfattning</h3>

          <span className="section-title">Kurs:</span>
          <div className="section-block">
            <span className="thick-text">{course.name}</span>
            <span className="text">{course.location}</span>
          </div>

          <span className="date section-title">
            Datum:
            <Link
              to={`/utbildningar/${slugify(course.name, {
                lower: true,
              })}/#kurser`}
            >
              <i className="section-title-icon fas fa-pen" />
            </Link>
          </span>
          <div className="section-block">
            <ul className="list">{dates}</ul>
          </div>

          <span className="section-title">Kursledare:</span>
          <div className="section-block">
            <ul className="list">{renderCourseLeaders}</ul>
          </div>

          <div className="total">
            {occasion.discount ? (
              <div className="total-single-row total-discount discount">
                {`-${occasion.discount}% på detta tillfälle`}
              </div>
            ) : null}

            <div className="total-pris-name">Total Pris:</div>
            <div className="total-pris-value">
              {occasion.discount
                ? formatCurrencySEK(coursePriceWithDiscount)
                : formatCurrencySEK(fullCoursePrice)}
            </div>

            <div className="total-item-name">
              {`${numberOfAttendees} deltagare`}
            </div>

            {occasion.discount ? (
              <div className="total-item-value">
                <span className="total-item-value ord-price">
                  {`Ord pris. ${formatCurrencySEK(fullCoursePrice)}`}
                </span>
              </div>
            ) : null}
          </div>

          <span className="detail--small">
            <FormCheckbox
              errorMessages={{
                [ErrorTypes.CHECKBOX_IS_REQUIRED]: 'Du måste acceptera GDPR',
              }}
              name="gdpr"
            >
              {`Jag godkänner `}
              <a className="hl--purple" href="/gdpr" target="_blank">
                GDPR
              </a>
              *
            </FormCheckbox>
          </span>

          <span className="detail--small">
            <FormCheckbox
              errorMessages={{
                [ErrorTypes.CHECKBOX_IS_REQUIRED]:
                  'Du måste acceptera de allmänna villkoren',
              }}
              name="terms"
            >
              {`Jag godkänner `}
              <a className="hl--purple" href="/villkor" target="_blank">
                allmänna villkor
              </a>
              *
            </FormCheckbox>
          </span>

          <div className="send-button">
            <Button arrowDirection="right" dark label="Skicka bokning" />
          </div>
          {/* <CaptchaTos /> */}
        </div>
      </Section>

      <style jsx>
        {`
          .order-summary-form {
            padding: 0 20px;
          }

          .order-summary-form::before {
            content: '';
            z-index: 5;
            position: absolute;
            height: 10px;
            width: 100%;
            background: ${bluishPurple};
            left: 0;
            top: 0;
          }

          .scroll__wrapper {
            width: 100%;
          }

          .date {
            display: block;
            position: relative;
          }

          .total {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 5px 0;
            padding: 20px 0;
            width: 100%;
          }

          .total-single-row,
          .total-pris-name,
          .total-pris-value,
          .total-item-name,
          .total-item-value {
            font-family: Lato;
          }
          .total-pris-name {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;
            color: ${greyishBrown};
            font-style: italic;
          }
          .total-pris-value {
            font-size: 25px;
            font-weight: bold;
            color: ${greenishcyan};
            text-align: right;
          }
          .total-item-name,
          .total-item-value {
            font-family: Lato;
            font-size: 14px;
            font-weight: 300;
            line-height: 2;
            color: ${greyishBrown};
          }
          .total-item-value {
            text-align: right;
          }
          .total-single-row {
            grid-column: 1 / 3;
          }

          .total-discount {
            text-align: right;
          }

          .discount {
            font-size: 16px;
            color: ${darkishPink};
          }

          .section-title {
            font-family: 'Lato';
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
            color: ${greyishBrown};
            display: block;
            margin-bottom: 10px;
          }
          .section-title-icon {
            position: absolute;
            top: 0;
            right: 10px;
            color: ${bluishPurple};
            border-radius: 50%;
            border: 1px solid ${veryLightPink};
            padding: 5px;
            cursor: pointer;
          }

          .section-block {
            background: white;
            padding: 15px;
            margin-bottom: 20px;
            position: relative;
          }

          .send-button {
            margin: 20px auto;
            text-align: center;
            width: 100%;
          }

          .list {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 18px;
            font-weight: 300;
          }
          .list :global(.list-items) {
            margin-bottom: 5px;
          }

          .detail--small {
            font-family: 'Lato';
            font-size: 14px;
            color: ${greyishBrown};
            display: flex;
            margin: 10px 0;
          }

          .thick-text {
            display: block;
            width: 100%;
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }
          .text {
            display: block;
            width: 100%;
            font-family: Lato;
            font-size: 18px;
            font-weight: 300;
            color: #000000;
          }

          .hl--purple {
            color: ${bluishPurple};
            font-weight: bold;
            text-decoration: none;
          }

          a.hl--purple:hover {
            text-decoration: underline;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .order-summary-form.sticky {
              position: fixed;
              right: 0;
              top: 0;
              bottom: 0;
              width: 40%;
              overflow: auto;
            }

            .sticky.order-summary-form::before {
              position: fixed;
              top: 0;
              right: 0;
              width: 40%;
              left: initial;
            }
          }

          @media (min-width: ${breakpoints.desktopMD}) {
            .order-summary-form.sticky {
              width: 30%;
            }

            .sticky.order-summary-form::before {
              width: 30%;
            }
          }
        `}
      </style>
    </div>
  );
};

OrderSummary.propTypes = {
  course: PropTypes.object.isRequired,
  coursePriceWithDiscount: PropTypes.number.isRequired,
  fullCoursePrice: PropTypes.number.isRequired,
  numberOfAttendees: PropTypes.number.isRequired,
  occasion: PropTypes.object.isRequired,
  orderSummaryClassName: PropTypes.string.isRequired,
};

export default OrderSummary;
