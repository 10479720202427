import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import OrderSummary from './OrderSummary';

const MEDIA_MEDIUM_BREAKPOINT = 768;

const OrderSummaryWrapper = ({
  course,
  numberOfAttendees,
  coursePrice,
  occasion,
  attendees,
}) => {
  const [orderSummaryClassName, setOrderSummaryClassName] = useState('');

  const orderSummaryScrollSpy = () => {
    // look up the navigation element
    const HEADER_HEIGHT = document
      .getElementById('navigation')
      .getBoundingClientRect().height;

    const { scrollY, innerWidth } = window;
    const hasScrolledBelowHeader = scrollY >= HEADER_HEIGHT;

    setOrderSummaryClassName(
      hasScrolledBelowHeader && innerWidth > MEDIA_MEDIUM_BREAKPOINT
        ? 'sticky'
        : ''
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', orderSummaryScrollSpy);

    // clean up
    return () => window.removeEventListener('scroll', orderSummaryScrollSpy);
  }, []);

  // calculate total price and discount
  const fullCoursePrice = coursePrice * numberOfAttendees;
  const courseDiscount = (fullCoursePrice * occasion.discount) / 100;
  const coursePriceWithDiscount = fullCoursePrice - courseDiscount;

  return (
    <OrderSummary
      attendees={attendees}
      course={course}
      coursePriceWithDiscount={coursePriceWithDiscount}
      fullCoursePrice={fullCoursePrice}
      numberOfAttendees={numberOfAttendees}
      occasion={occasion}
      orderSummaryClassName={orderSummaryClassName}
    />
  );
};

OrderSummaryWrapper.propTypes = {
  attendees: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  coursePrice: PropTypes.number.isRequired,
  numberOfAttendees: PropTypes.number.isRequired,
  occasion: PropTypes.object.isRequired,
};

export default OrderSummaryWrapper;
